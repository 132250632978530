<template>
  <v-container>
    <v-row>
        <v-col style="text-align:center;">
            <h2>{{index}}</h2>
            <sub><h3>Maxiumum of 4 deep</h3></sub>
        </v-col>
    </v-row>
    <v-row>
        <v-col v-for="lang in languages" :key="`col_${lang}`">
            <h3>{{lang}}</h3>
            <v-textarea v-model="model[lang]"/>
            <div v-html="model[lang]"/>
        </v-col>
    </v-row>
    <v-row>
        <v-col style="text-align: center;">
            <v-btn @click="newLabel">
                <v-icon>
                    mdi-new-box
                </v-icon>
            </v-btn>
        </v-col>
        <v-col style="text-align: center;">
            <v-btn @click="save" :disabled="index.length<5">
                <v-icon>
                    mdi-content-save
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    props: {
        index: {
            type: String,
            required: true
        },
        suffix: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        },
    },
    data: function(){
        return {
            ui: {
                mode: 'update'
            },
            config: {
                modes: ['update','new']
            }
        }
    },
    methods: {
        newLabel: function(){
            this.$router.push({
                name: 'newLabel'
            })
        },
        save: async function(){
            let response = await this.sendRequest('patch','/api/label/save',{
                key: this.index,
                suffix: this.suffix,
                label: this.model
            })
            this.$emit('initLabels')
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>