<template>
  <div>
    <v-expansion-panels>
        <v-expansion-panel v-for="(moduleLabels, moduleKey) in labels" :key="`${moduleKey}`">
            <v-expansion-panel-header>
                {{moduleKey}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <template v-for="(moduleLabel, moduleIndex) in moduleLabels">
                    <template v-if="moduleLabel._text">
                        <v-list-item :key="`${moduleKey}.${moduleIndex}`">
                            <v-list-item-title>
                                {{moduleIndex}}
                            </v-list-item-title>
                            <v-btn icon @click="edit(`${moduleKey}.${moduleIndex}`)">
                                <v-icon small>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </v-list-item>
                    </template>

                    <template v-else>

                        <v-expansion-panels :key="`${moduleKey}.${moduleIndex}`">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    {{moduleIndex}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <template v-for="(sectionLabel, sectionKey) in moduleLabel">
                                        <template v-if="sectionLabel._text">
                                            <v-list-item :key="`${moduleKey}.${moduleIndex}.${sectionKey}`">
                                                <v-list-item-title>
                                                    {{sectionKey}}
                                                </v-list-item-title>
                                                <v-btn icon @click="edit(`${moduleKey}.${moduleIndex}.${sectionKey}`)">
                                                    <v-icon small>
                                                        mdi-pencil
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item>
                                        </template>


                                        <template v-else>
                                            
                                            <v-expansion-panels :key="`${moduleKey}.${moduleIndex}.${sectionKey}`">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        {{sectionKey}}
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <template v-for="(subSectionLabel, subSectionKey) in sectionLabel">
                                                            <template v-if="subSectionLabel._text">
                                                                <v-list-item :key="`${moduleKey}.${moduleIndex}.${sectionKey}.${subSectionKey}`">
                                                                    <v-list-item-title>
                                                                        {{subSectionKey}}
                                                                    </v-list-item-title>
                                                                    <v-btn icon @click="edit(`${moduleKey}.${moduleIndex}.${sectionKey}.${subSectionKey}`)">
                                                                        <v-icon small>
                                                                            mdi-pencil
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-list-item>
                                                            </template>

                                                            <template v-else>
                                                                <v-expansion-panels :key="`${moduleKey}.${moduleIndex}.${sectionKey}.${subSectionKey}`">
                                                                    <v-expansion-panel>
                                                                        <v-expansion-panel-header>
                                                                            {{subSectionKey}}
                                                                        </v-expansion-panel-header>
                                                                        <v-expansion-panel-content>
                                                                            <template v-for="(subSectionChildLabel, subSectionChildKey) in subSectionLabel">
                                                                                <template v-if="subSectionChildLabel._text">
                                                                                    <v-list-item :key="`${moduleKey}.${moduleIndex}.${sectionKey}.${subSectionKey}.${subSectionChildKey}`">
                                                                                        <v-list-item-title>
                                                                                            {{subSectionChildKey}}
                                                                                        </v-list-item-title>
                                                                                        <v-btn icon @click="edit(`${moduleKey}.${moduleIndex}.${sectionKey}.${subSectionKey}.${subSectionChildKey}`)">
                                                                                            <v-icon small>
                                                                                                mdi-pencil
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </v-list-item>
                                                                                </template>
                                                                            </template>
                                                                        </v-expansion-panel-content>

                                                                    </v-expansion-panel>
                                                                </v-expansion-panels>
                                                            </template>
                                                        </template>
                                                    </v-expansion-panel-content>

                                                </v-expansion-panel>
                                            </v-expansion-panels>                                            
                                        </template>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </template>                    
                </template>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
    props: {
        labels: {
            type: Object,
            required: true
        }
    },
    data: function(){
        return {}
    },
    methods: {
        isLabels: function(object){
            let isLabel = false
            let objectKeys = Object.keys(object)
            if(objectKeys.includes('en-ca') || objectKeys.includes('fr-ca')){
                isLabel = true
            }

            return isLabel
        },
        edit: function(index){
            this.$router.push({name:'editLabel', params:{index}})
        }
    },
    computed: {

    }
}
</script>

<style>

</style>